@import url("../colors.css");

.vbc-app-left-side-bar {
  overflow: auto;
  height: 100vh;
  min-width: 130px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #f9f9f9;
  box-shadow: 10px 10px 20px #d4d4d4, -10px -10px 20px #ffffff;
}

.vbc-app-header {
  height: 90px;
  border-bottom: 1px solid var(--solid-gray);
  
}

.sidebar {
  padding: 15px;
  padding-bottom: 0;
  margin-top: 10px;
  font-size: 0.8rem;
  
}

.sidebar-icon {
  position: relative;
  bottom: -25px;
}

.link-selected {
  color: var(--theme-default);
}

.vbc-title-component {
  /* display: flex;
  align-items: center; */
  padding: 10px;
}

.vbc-title-dashboard {
  margin-left: 15px;
}

.otp-input {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 8px;
}

.neomorphic-login {
  border-radius: 12px;
  background: #f3f4f6;
  box-shadow: 6px 6px 12px #c2c3c5, -6px -6px 12px #ffffff;
}

/* dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: max-content;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 5px;
}

.dropdown-content ul li {
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.upgrade-plan-table-height {
  min-height: max-content;
  max-height: 66.67%;
}

/* modal */

.modal {
  /* width: 500px; */
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(black, 0.2);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity 0.25s ease;
}
.modal-content {
  transition: top 0.25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  width: fit-content;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  height: fit-content;
}

/* modal */

p {
  color: rgba(0, 0, 0, 0.6);
}

::placeholder {
  color: red;
}

.alert-wrapper {
  border-radius: 5px;
  border: 1px solid cornflowerblue;
  background-color: #e9f0fc;
  color: black;
}

.text-2xl{
  width:max-content 
}
.yourCloseIconClass {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .yourCloseIconClass {
    position: relative;
    left: -35px;
  }
  .searchfield {
    border: 1px solid;
    width: 80% !important;
  }
}
.searchfield {
  border: 1px solid;
  width: 50%;
}
/* @media only screen and (max-width: 1180px){
  .vbc-app-header{
    width: 100%;
    padding : 0 2%;
    }
}
@media only screen and (max-width: 998px){
  .vbc-app-header{
    width: 100%;
    padding : 0 2%;
    }
}
@media only screen and (max-width: 660px){
  .vbc-app-header{
    width: 100%;
    padding : 0 2%;
    background-attachment: red;
    }
} */
/* 
@media only screen and (max-width: 998px){
h1 {font-size: 200%}
}

@media only screen and (max-width: 768px){
  h1 {font-size: 180%}
  } */
/* 
  @media screen and (max-width: 400px) {
    .sidebar {
      text-align: center;
      float: none;
    }
  } */

  .toggle-sidebar-btn {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: #333; /* Change this to fit your color scheme */
    transition: color 0.3s ease;
}

.toggle-sidebar-btn:hover {
    color: #555; /* Darker shade for hover effect */
}
.toggle-header {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #333; /* Change this to fit your color scheme */
  transition: color 0.3s ease;
}

.toggle-header:hover {
  color: #555; /* Darker shade for hover effect */
}
/* Hide sidebar by default on mobile view */
@media only screen and (max-width: 768px) {
  .vbc-app-left-side-bar {
    display: none;
  }
}

/* Show sidebar on mobile view when isSidebarOpen is true */
@media only screen and (max-width: 768px) {
  .vbc-app-left-side-bar.open {
    display: flex;
  }
   /* .toggle-header {
    display: none !important;
} */


}


@media only screen and (max-width: 768px) { 
  .vbc-app-left-side-bar {
      display: none;
  }
}
.toggle-sidebar-btn {
  display: none; 
  position: fixed;
  top: 10px;
  left: 91px;
  z-index: 1000; 
  color: black;
  border: none;
  padding: 10px;
  border-radius: 4px;
}

.toggle-header {
  display: none; 
  position: fixed;
  top: 18px;
  left: 9px;
  z-index: 1000; 
  color: black;
  border: none;
  padding: 10px;
  border-radius: 4px;
}
@media only screen and (max-width: 768px) { 
  .toggle-sidebar-btn {
      display: block;
  }
  .toggle-header{
    display: block;

  }
  .SidePane_sidePane__pane__9tRBZ{
    width: 100% !important
  }
  .SidePane_sidePane__backdrop__7KcYU{
    width: 80% !important
  }
}


